// Объект с переводами
const KEYS = {
  
  basicInfo: {
    ru: "Основная информация",
    en: "Basic Information",
    uk: "Основна інформація"
  },
  achievements: {
    ru: "Достижения",
    en: "Achievements",
    uk: "Досягнення"
  },
  myNutritionist: {
    ru: "Мой диетолог",
    en: "My Nutritionist",
    uk: "Мій дієтолог"
  },
  nextAppointment: {
    ru: "Следующая консультация",
    en: "Next Appointment",
    uk: "Наступна консультація"
  },
  book: {
    ru: "Записаться",
    en: "Book",
    uk: "Записатися"
  },
  fields: {
    name: {
      ru: "Имя",
      en: "Name",
      uk: "Ім'я"
    },
    weight: {
      ru: "Текущий вес",
      en: "Current Weight",
      uk: "Поточна вага"
    },
    height: {
      ru: "Рост",
      en: "Height",
      uk: "Зріст"
    },
    age: {
      ru: "Возраст",
      en: "Age",
      uk: "Вік"
    },
    gender: {
      ru: "Пол",
      en: "Gender",
      uk: "Стать"
    },
    lifestyle: {
      ru: "Образ жизни",
      en: "Lifestyle",
      uk: "Спосіб життя"
    }
  },
  gender: {
    male: {
      ru: "Мужской",
      en: "Male",
      uk: "Чоловіча"
    },
    female: {
      ru: "Женский",
      en: "Female",
      uk: "Жіноча"
    }
  },
  units: {
    kg: {
      ru: "кг",
      en: "kg",
      uk: "кг"
    },
    cm: {
      ru: "см",
      en: "cm",
      uk: "см"
    },
    years: {
      ru: "лет",
      en: "years",
      uk: "років"
    },
    kcal: {  // Перемещаем на тот же уровень, что и kg, cm, years
      ru: "ккал",
      en: "kcal",
      uk: "ккал"
    }
  },
  lifestyleOptions: {
    inactive: {
      label: {
        ru: "Неактивный",
        en: "Inactive",
        uk: "Неактивний"
      },
      description: {
        ru: "Сидячий образ жизни, минимум движения",
        en: "Sedentary lifestyle, minimal movement",
        uk: "Сидячий спосіб життя, мінімум руху"
      }
    },
    light: {
      label: {
        ru: "Легкий",
        en: "Light",
        uk: "Легкий"
      },
      description: {
        ru: "Легкие упражнения/спорт 1-3 раза в неделю",
        en: "Light exercises/sports 1-3 times a week",
        uk: "Легкі вправи/спорт 1-3 рази на тиждень"
      }
    },
    moderate: {
      label: {
        ru: "Умеренный",
        en: "Moderate",
        uk: "Помірний"
      },
      description: {
        ru: "Умеренные упражнения/спорт 3-5 раз в неделю",
        en: "Moderate exercises/sports 3-5 times a week",
        uk: "Помірні вправи/спорт 3-5 разів на тиждень"
      }
    },
    active: {
      label: {
        ru: "Активный",
        en: "Active",
        uk: "Активний"
      },
      description: {
        ru: "Интенсивные упражнения/спорт 6-7 раз в неделю",
        en: "Intensive exercises/sports 6-7 times a week",
        uk: "Інтенсивні вправи/спорт 6-7 разів на тиждень"
      }
    },
    very_active: {
      label: {
        ru: "Очень активный",
        en: "Very Active",
        uk: "Дуже активний"
      },
      description: {
        ru: "Спортсмены, физическая работа",
        en: "Athletes, physical work",
        uk: "Спортсмени, фізична робота"
      }
    }
  },
  settings: {
    language: {
      ru: "Настройки языка",
      en: "Language Settings",
      uk: "Налаштування мови"
    },
    selectLanguage: {
      ru: "Выберите язык интерфейса",
      en: "Select interface language",
      uk: "Оберіть мову інтерфейсу"
    }
  },
  feedback: {
    title: {
      ru: "Обратная связь",
      en: "Feedback",
      uk: "Зворотній зв'язок"
    },
    write: {
      ru: "Написать",
      en: "Write",
      uk: "Написати"
    },
    cancel: {
      ru: "Отмена",
      en: "Cancel",
      uk: "Скасувати"
    },
    send: {
      ru: "Отправить",
      en: "Send",
      uk: "Надіслати"
    },
    sending: {
      ru: "Отправка...",
      en: "Sending...",
      uk: "Надсилання..."
    },
    message: {
      ru: "Сообщение",
      en: "Message",
      uk: "Повідомлення"
    },
    messagePlaceholder: {
      ru: "Опишите ваше предложение или вопрос...",
      en: "Describe your suggestion or question...",
      uk: "Опишіть вашу пропозицію або запитання..."
    },
    successMessage: {
      ru: "Спасибо! Ваше сообщение успешно отправлено.",
      en: "Thank you! Your message has been sent successfully.",
      uk: "Дякуємо! Ваше повідомлення успішно надіслано."
    },
    errorMessage: {
      ru: "Произошла ошибка при отправке. Пожалуйста, попробуйте позже.",
      en: "An error occurred while sending. Please try again later.",
      uk: "Виникла помилка під час надсилання. Будь ласка, спробуйте пізніше."
    }
  },
analytics: {
  title: {
    ru: "Анализ показателей",
    en: "Metrics Analysis",
    uk: "Аналіз показників"
  },
  basalMetabolism: {
    title: {
      ru: "Базовый метаболизм (BMR)",
      en: "Basal Metabolic Rate (BMR)",
      uk: "Базовий метаболізм (BMR)"
    },
    description: {
      ru: "Минимальный расход калорий в состоянии покоя",
      en: "Minimum calorie expenditure at rest",
      uk: "Мінімальна витрата калорій у стані спокою"
    }
  },
  dailyRate: {
    title: {
      ru: "Суточная норма (TDEE)",
      en: "Total Daily Energy Expenditure (TDEE)",
      uk: "Добова норма (TDEE)"
    },
    description: {
      ru: "Общий расход калорий с учетом активности",
      en: "Total calorie expenditure including activity",
      uk: "Загальна витрата калорій з урахуванням активності"
    }
  },
  weightRecommendations: {
    title: {
      ru: "Рекомендации по весу",
      en: "Weight Recommendations",
      uk: "Рекомендації щодо ваги"
    },
    bmi: {
      ru: "Индекс массы тела (BMI)",
      en: "Body Mass Index (BMI)",
      uk: "Індекс маси тіла (BMI)"
    },
    recommendedRange: {
      ru: "Рекомендуемый диапазон",
      en: "Recommended Range",
      uk: "Рекомендований діапазон"
    },
    idealWeight: {
      ru: "Идеальный вес",
      en: "Ideal Weight",
      uk: "Ідеальна вага"
    },
    changeRate: {
      ru: "Рекомендуемая скорость изменения",
      en: "Recommended Change Rate",
      uk: "Рекомендована швидкість зміни"
    },
    timeToGoal: {
      ru: "Примерное время до цели",
      en: "Estimated Time to Goal",
      uk: "Приблизний час до мети"
    }
  },
  targetCalories: {
    title: {
      ru: "Целевые калории",
      en: "Target Calories",
      uk: "Цільові калорії"
    },
    maintenance: {
      title: {
        ru: "Поддержание веса",
        en: "Weight Maintenance",
        uk: "Підтримка ваги"
      },
      description: {
        ru: "Сохранение текущего веса",
        en: "Maintaining current weight",
        uk: "Збереження поточної ваги"
      }
    },
    loss: {
      title: {
        ru: "Снижение веса",
        en: "Weight Loss",
        uk: "Зниження ваги"
      },
      description: {
        ru: "Дефицит 15%",
        en: "15% deficit",
        uk: "Дефіцит 15%"
      }
    },
    gain: {
      title: {
        ru: "Набор веса",
        en: "Weight Gain",
        uk: "Набір ваги"
      },
      description: {
        ru: "Профицит 15%",
        en: "15% surplus",
        uk: "Профіцит 15%"
      }
    },
    custom: {
      title: {
        ru: "Своё значение",
        en: "Custom Value",
        uk: "Власне значення"
      },
      description: {
        ru: "Пользовательская настройка",
        en: "Custom setting",
        uk: "Користувацьке налаштування"
      },
      addButton: {
        ru: "Указать своё значение",
        en: "Set custom value",
        uk: "Вказати власне значення"
      },
      add: {
        ru: "Добавить",
        en: "Add",
        uk: "Додати"
      },
      cancel: {
        ru: "Отмена",
        en: "Cancel",
        uk: "Скасувати"
      },
      placeholder: {
        ru: "Калории",
        en: "Calories",
        uk: "Калорії"
      }
    }
  }
},
weightProgress: {
  title: {
    ru: "Цель по весу",
    en: "Weight Goal",
    uk: "Ціль по вазі"
  },
  weightRange: {
    title: {
      ru: "Диапазон веса",
      en: "Weight Range",
      uk: "Діапазон ваги"
    },
    currentWeight: {
      ru: "Текущий вес",
      en: "Current Weight",
      uk: "Поточна вага"
    },
    targetWeight: {
      ru: "Целевой вес",
      en: "Target Weight",
      uk: "Цільова вага"
    },
    zones: {
      underweight: {
        title: {
          ru: "Недостаточный вес",
          en: "Underweight",
          uk: "Недостатня вага"
        },
        description: {
          ru: "меньше",
          en: "less than",
          uk: "менше"
        }
      },
      normal: {
        title: {
          ru: "Рекомендуемый вес",
          en: "Recommended Weight",
          uk: "Рекомендована вага"
        },
        description: {
          ru: "от",
          en: "from",
          uk: "від"
        },
        to: {
          ru: "до",
          en: "to",
          uk: "до"
        }
      },
      overweight: {
        title: {
          ru: "Избыточный вес",
          en: "Overweight",
          uk: "Надмірна вага"
        },
        description: {
          ru: "больше",
          en: "more than",
          uk: "більше"
        }
      }
    }
  },
  goalTypes: {
    loss: {
      ru: "Снижение веса",
      en: "Weight Loss",
      uk: "Зниження ваги"
    },
    gain: {
      ru: "Набор веса",
      en: "Weight Gain",
      uk: "Набір ваги"
    },
    maintain: {
      ru: "Поддержание веса",
      en: "Weight Maintenance",
      uk: "Підтримка ваги"
    }
  },
  weeks: {
    ru: "недель",
    en: "weeks",
    uk: "тижнів"
  },
  history: {
    title: {
      ru: "История изменений",
      en: "Weight History",
      uk: "Історія змін"
    },
    date: {
      options: {
        ru: {
          day: 'numeric',
          month: 'short',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        en: {
          day: 'numeric',
          month: 'short',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        },
        uk: {
          day: 'numeric',
          month: 'short',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }
      }
    }
  }
}
};

// Функция для получения перевода с поддержкой резервного языка
const getTranslation = (key, language = 'en') => {
  try {
    const keys = key.split('.');
    let result = KEYS;

    for (const k of keys) {
      result = result[k];
      if (!result) throw new Error(); // Прерывание, если ключ не найден
    }

    // Если перевод найден, вернуть его. Иначе, пробуем резервный язык
    return result[language] || result['en'] || key;
  } catch (error) {
    console.error(`Translation not found for key: ${key}`);
    return key; // Возврат ключа, если перевод недоступен
  }
};

export { KEYS, getTranslation };