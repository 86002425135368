import React, { useState, useRef, useEffect } from 'react';
import { FaCamera, FaImage, FaKeyboard, FaEdit, FaTrash, FaInfoCircle, FaTimes } from "react-icons/fa";
import Anthropic from "@anthropic-ai/sdk";
import { GiMeat as ProteinIcon, GiBreadSlice as CarbsIcon, GiAvocado as FatsIcon, GiFruitBowl as CaloriesIcon } from 'react-icons/gi';

export const useMealManager = () => {
  const [meals, setMeals] = useState([
    { id: 1, name: "Овсянка", calories: 300, proteins: 10, carbs: 45, fats: 5, time: "08:00", image: "/images/sa.png" },
    { id: 2, name: "Салат с курицей", calories: 450, proteins: 30, carbs: 20, fats: 15, time: "13:00", image: "/images/ov.png" },
    { id: 3, name: "Яблоко", calories: 95, proteins: 0, carbs: 25, fats: 0, time: "16:00", image: "/images/jb.png" }
  ]);
  const [newMeal, setNewMeal] = useState({ calories: "", proteins: "", carbs: "", fats: "", image: "", description: "", fullDescription: "" });
  const [editingMeal, setEditingMeal] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModalTab, setActiveModalTab] = useState("camera");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const videoRef = useRef(null);

  const handleEditMeal = (meal) => {
    setEditingMeal(meal);
    setNewMeal({
      calories: meal.calories,
      proteins: meal.proteins,
      carbs: meal.carbs,
      fats: meal.fats,
      image: meal.image,
      description: meal.name,
      fullDescription: meal.fullDescription
    });
    setIsModalOpen(true);
  };

  const handleDeleteMeal = (id) => {
    setMeals(meals.filter(meal => meal.id !== id));
  };

  const getCurrentTime = () => {
    const now = new Date();
    return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
  };

  const handleAddMeal = () => {
    const currentTime = getCurrentTime();
    const updatedMeal = {
      ...newMeal,
      id: editingMeal ? editingMeal.id : Date.now(),
      time: editingMeal ? editingMeal.time : currentTime,
      name: newMeal.description || "Без названия",
    };

    setMeals(prevMeals => 
      editingMeal
        ? prevMeals.map(meal => meal.id === updatedMeal.id ? updatedMeal : meal)
        : [...prevMeals, updatedMeal]
    );

    setNewMeal({ calories: "", proteins: "", carbs: "", fats: "", image: "", description: "" });
    setEditingMeal(null);
    setIsModalOpen(false);
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: "environment"
        } 
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  const analyzeImage = async (imageDataUrl) => {
    setIsAnalyzing(true);
    try {
      let base64Image = imageDataUrl;
      if (base64Image.includes('data:image/jpeg;base64,')) {
        base64Image = base64Image.replace('data:image/jpeg;base64,', '');
      } else if (base64Image.includes('data:image/png;base64,')) {
        const canvas = document.createElement('canvas');
        const img = new Image();
        
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = imageDataUrl;
        });
        
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        base64Image = canvas.toDataURL('image/jpeg', 0.9).replace('data:image/jpeg;base64,', '');
      }

      const anthropic = new Anthropic({
        apiKey: "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",
        dangerouslyAllowBrowser: true
      });

      const messageRequest = {
        model: "claude-3-5-sonnet-20241022",
        max_tokens: 1000,
        temperature: 0,
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: "Проанализируй это изображение еды. Предоставь ответ в следующем формате:\ndescription: [Краткое описание блюда и видимых ингредиентов в 1-4 словах]\ncalories: [Приблизительное количество калорий для всей порции, только цифры]\nproteins: [Приблизительное количество белков в граммах, только число]\nfats: [Приблизительное количество жиров в граммах, только число]\ncarbs: [Приблизительное количество углеводов в граммах, только число]"
              },
              {
                type: "image",
                source: {
                  type: "base64",
                  media_type: "image/jpeg",
                  data: base64Image
                }
              }
            ]
          }
        ]
      };

      const msg = await anthropic.messages.create(messageRequest);

      if (!msg.content || !msg.content[0] || !msg.content[0].text) {
        throw new Error('Invalid response format from API');
      }

      const messageText = msg.content[0].text;
      
      const descriptionMatch = messageText.match(/description:\s*(.+?)(?=\n|$)/i);
      const caloriesMatch = messageText.match(/calories:\s*(\d+)/i);
      const proteinsMatch = messageText.match(/proteins:\s*(\d+)/i);
      const fatsMatch = messageText.match(/fats:\s*(\d+)/i);
      const carbsMatch = messageText.match(/carbs:\s*(\d+)/i);
      const fullDescriptionMatch = messageText.match(/carbs:\s*\d+\s*([\s\S]*)/i);
      const fullDescription = fullDescriptionMatch ? fullDescriptionMatch[1].trim() : '';

      const description = descriptionMatch?.[1]?.trim() || 'Неизвестное блюдо';
      const calories = caloriesMatch ? parseInt(caloriesMatch[1]) : 0;
      const proteins = proteinsMatch ? parseInt(proteinsMatch[1]) : 0;
      const fats = fatsMatch ? parseInt(fatsMatch[1]) : 0;
      const carbs = carbsMatch ? parseInt(carbsMatch[1]) : 0;

      setNewMeal(prevMeal => ({
        ...prevMeal,
        description: description || 'Неизвестное блюдо',
        calories: calories || 0,
        proteins: proteins || 0,
        fats: fats || 0,
        carbs: carbs || 0,
        fullDescription: fullDescription
      }));

    } catch (error) {
      console.error('Error in analyzeImage:', error);
      if (error.response) {
        console.error('API Error Details:', error.response.data);
      }
      setNewMeal(prevMeal => ({
        ...prevMeal,
        description: `Ошибка анализа изображения: ${error.message}`,
        calories: 0,
        proteins: 0,
        fats: 0,
        carbs: 0
      }));
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewMeal(prevMeal => ({ ...prevMeal, image: reader.result }));
        analyzeImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const capturePhoto = async () => {
    if (videoRef.current) {
      setIsAnalyzing(true);
      try {
        const canvas = document.createElement("canvas");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
        const imageDataUrl = canvas.toDataURL("image/jpeg");
        setNewMeal(prevMeal => ({ ...prevMeal, image: imageDataUrl }));
        await analyzeImage(imageDataUrl);
      } finally {
        setIsAnalyzing(false);
      }
    }
  };

  return {
    meals,
    newMeal,
    editingMeal,
    isModalOpen,
    activeModalTab,
    isAnalyzing,
    videoRef,
    handleEditMeal,
    handleDeleteMeal,
    handleAddMeal,
    handleImageUpload,
    setNewMeal,
    setEditingMeal,
    setIsModalOpen,
    setActiveModalTab,
    startCamera,
    stopCamera,
    capturePhoto,
    analyzeImage
  };
};

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-8 flex flex-col items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500 mb-4"></div>
      <p className="text-gray-700 text-lg font-medium">Анализирую изображение...</p>
      <p className="text-gray-500 text-sm mt-2">Это займет несколько секунд</p>
    </div>
  </div>
);

const TabButton = ({ icon: Icon, tab, label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`flex flex-col items-center gap-2 p-4 rounded-xl transition-all duration-300 w-full
      ${isActive 
        ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg scale-105" 
        : "bg-gray-50 text-gray-700 hover:bg-gray-100 hover:scale-102"}`}
  >
    <Icon size={24} className="transition-transform duration-300 group-hover:scale-110" />
    <span className="text-sm font-medium">{label}</span>
  </button>
);

const NutrientInput = ({ label, value, onChange, unit = "г", icon: Icon, color }) => (
  <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-300">
    <div className="flex items-center gap-3 mb-2">
      <div className={`p-2 rounded-lg ${color}`}>
        <Icon size={20} className="text-white" />
      </div>
      <label className="text-sm font-medium text-gray-700">{label}</label>
    </div>
    <div className="relative">
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        placeholder="0"
        onClick={(e) => e.stopPropagation()}
      />
      <span className="absolute right-3 top-2.5 text-gray-400 text-sm font-medium">
        {unit}
      </span>
    </div>
  </div>
);

const ImagePreview = ({ image, activeTab, videoRef }) => {
  if (activeTab === "camera") {
    return (
      <>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="w-full h-64 object-cover"
        />
        {image && (
          <img 
            src={image} 
            alt="Preview" 
            className="absolute inset-0 w-full h-64 object-cover" 
          />
        )}
      </>
    );
  }

  if (activeTab === "gallery" && image) {
    return <img src={image} alt="Preview" className="w-full h-64 object-cover" />;
  }

  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-gray-400">
        <FaImage size={48} className="mx-auto mb-2" />
        <p>Нет выбранного изображения</p>
      </div>
    </div>
  );
};

export const MealModal = ({ mealManager }) => {
  useEffect(() => {
    const shouldStartCamera = mealManager.isModalOpen && 
                            mealManager.activeModalTab === "camera";
    
    if (shouldStartCamera) {
      mealManager.startCamera();
    }

    return () => {
      if (mealManager.activeModalTab === "camera") {
        mealManager.stopCamera();
      }
    };
  }, [mealManager, mealManager.isModalOpen, mealManager.activeModalTab]);

  if (!mealManager.isModalOpen) return null;

  const handleNutrientChange = (field, value) => {
    mealManager.setNewMeal(prevMeal => ({
      ...prevMeal,
      [field]: value
    }));
  };

  const handleClose = () => {
    mealManager.setIsModalOpen(false);
    mealManager.stopCamera();
    mealManager.setEditingMeal(null);
    mealManager.setNewMeal({
      calories: "",
      image: "",
      description: "",
      proteins: "",
      carbs: "",
      fats: ""
    });
  };

  const handleTabChange = (tab) => {
    mealManager.setActiveModalTab(tab);
    tab === "camera" ? mealManager.startCamera() : mealManager.stopCamera();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      {mealManager.isAnalyzing && <LoadingSpinner />}
      <div className="bg-gray-50 rounded-2xl w-full max-w-md overflow-y-auto max-h-[90vh]">
        <div className="relative">
          <div className="bg-white">
            <div className="h-64 bg-gray-100">
              <ImagePreview
                image={mealManager.newMeal.image}
                activeTab={mealManager.activeModalTab}
                videoRef={mealManager.videoRef}
              />
            </div>
          </div>
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 z-10 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all duration-300 hover:rotate-90"
          >
            <FaTimes size={20} />
          </button>

          <div className="p-6 space-y-6">
            <h3 className="text-2xl font-bold text-gray-800 text-center">
              {mealManager.editingMeal ? "Редактировать прием пищи" : "Добавить прием пищи"}
            </h3>

            <div className="grid grid-cols-3 gap-3">
              {[
                { icon: FaCamera, tab: "camera", label: "Камера" },
                { icon: FaImage, tab: "gallery", label: "Галерея" },
                { icon: FaKeyboard, tab: "manual", label: "Вручную" }
              ].map(({ icon, tab, label }) => (
                <TabButton
                  key={tab}
                  icon={icon}
                  tab={tab}
                  label={label}
                  isActive={mealManager.activeModalTab === tab}
                  onClick={() => handleTabChange(tab)}
                />
              ))}
            </div>

            {mealManager.activeModalTab === "camera" && (
              <button
                onClick={mealManager.capturePhoto}
                disabled={mealManager.isAnalyzing}
                className={`w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 px-4 rounded-xl transition-all duration-300 shadow-lg hover:shadow-xl font-medium ${
                  mealManager.isAnalyzing ? 'opacity-50 cursor-not-allowed' : 'hover:from-blue-600 hover:to-blue-700'
                }`}
              >
                {mealManager.newMeal.image ? "Переснять фото" : "Сделать фото"}
              </button>
            )}

            {mealManager.activeModalTab === "gallery" && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Выберите фото
                </label>
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={mealManager.handleImageUpload}
                    disabled={mealManager.isAnalyzing}
                    className={`w-full px-4 py-3 border-2 border-dashed border-gray-300 rounded-xl transition-all duration-300 hover:border-blue-500 ${
                      mealManager.isAnalyzing ? 'opacity-50 cursor-not-allowed' : 'focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                    }`}
                  />
                </div>
              </div>
            )}

            {(mealManager.activeModalTab === "manual" || mealManager.newMeal.image) && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Описание блюда
                  </label>
                  <textarea
                    value={mealManager.newMeal.description}
                    onChange={(e) => mealManager.setNewMeal({
                      ...mealManager.newMeal,
                      description: e.target.value
                    })}
                    className="w-full px-4 py-3 bg-white border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                    placeholder="Опишите ваше блюдо"
                    rows="3"
                  />
                </div>

                {mealManager.newMeal.fullDescription && (
                  <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100">
                    <div className="flex items-center gap-2 mb-2">
                      <FaInfoCircle className="text-blue-500" />
                      <h4 className="font-medium text-gray-700">Анализ блюда</h4>
                    </div>
                    <p className="text-gray-600 text-sm whitespace-pre-line">
                      {mealManager.newMeal.fullDescription}
                    </p>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                  <NutrientInput
                    label="Калории"
                    value={mealManager.newMeal.calories}
                    onChange={(value) => handleNutrientChange('calories', value)}
                    unit="ккал"
                    icon={CaloriesIcon}
                    color="bg-orange-500"
                  />
                  <NutrientInput
                    label="Белки"
                    value={mealManager.newMeal.proteins}
                    onChange={(value) => handleNutrientChange('proteins', value)}
                    icon={ProteinIcon}
                    color="bg-red-500"
                  />
                  <NutrientInput
                    label="Углеводы"
                    value={mealManager.newMeal.carbs}
                    onChange={(value) => handleNutrientChange('carbs', value)}
                    icon={CarbsIcon}
                    color="bg-yellow-500"
                  />
                  <NutrientInput
                    label="Жиры"
                    value={mealManager.newMeal.fats}
                    onChange={(value) => handleNutrientChange('fats', value)}
                    icon={FatsIcon}
                    color="bg-green-500"
                  />
                </div>
              </div>
            )}

            <div className="pt-0 bg-gray-50">
              <button
                onClick={mealManager.handleAddMeal}
                disabled={mealManager.isAnalyzing}
                className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white py-4 px-6 rounded-xl transition-all duration-300 shadow-lg hover:shadow-xl font-medium text-lg ${
                  mealManager.isAnalyzing ? 'opacity-50 cursor-not-allowed' : 'hover:from-green-600 hover:to-green-700'
                }`}
              >
                {mealManager.editingMeal ? "Сохранить изменения" : "Добавить"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MealList = ({ meals, onEdit, onDelete }) => {
  return (
    <ul className="space-y-4">
      {meals.map((meal) => (
        <li key={meal.id} className="bg-white p-4 rounded-lg shadow transition duration-300 hover:shadow-md flex items-center">
          {meal.image && (
            <div className="w-16 h-16 rounded-full overflow-hidden mr-4 flex-shrink-0">
              <img src={meal.image} alt={meal.name} className="w-full h-full object-cover" />
            </div>
          )}
          <div className="flex-grow">
            <div className="flex justify-between items-center">
              <div>
                <span className="text-lg font-semibold text-green-800">{meal.name}</span>
                <p className="text-sm text-green-600">{meal.time}</p>
                <div className="text-sm text-gray-600 flex items-center">
                  <FaInfoCircle className="mr-1" />
                  <span>{meal.calories} кал, {meal.proteins}г белки, {meal.carbs}г углеводы, {meal.fats}г жиры</span>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => onEdit(meal)}
                  className="text-blue-500 hover:text-blue-700 transition duration-300"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => onDelete(meal.id)}
                  className="text-red-500 hover:text-red-700 transition duration-300"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};