import React, { useState, useCallback } from 'react';
import { FiEdit3} from 'react-icons/fi';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, ReferenceArea } from 'recharts';
import { calculateHealthMetrics } from './Analytics';
import { getTranslation } from './translations';
const WeightRangeVisualizer = ({ 
  initialCurrentWeight = 70,
  initialTargetWeight = 65,
  recommendedRange = { min: 60, max: 80 },
  onCurrentWeightChange,
  onTargetWeightChange,
  language
}) => {
  const [currentWeight, setCurrentWeight] = useState(initialCurrentWeight);
  const [targetWeight, setTargetWeight] = useState(initialTargetWeight);
  const [isDragging, setIsDragging] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const formatWeight = (weight) => {
    return typeof weight === 'number' ? weight.toFixed(1) : '0.0';
  };

  const getPositionY = (weight) => {
    const safeWeight = typeof weight === 'number' ? weight : 0;
    return `${100 - ((Math.min(Math.max(safeWeight, 30), 150) - 30) / 120) * 100}%`;
  };

  const getWeightFromPosition = useCallback((y) => {
    const rect = document.querySelector('.weight-scale')?.getBoundingClientRect();
    if (!rect) return null;
    
    const percentage = (y - rect.top) / rect.height;
    const weight = 150 - (percentage * 120);
    return Math.min(Math.max(Math.round(weight * 10) / 10, 30), 150);
  }, []);

  const handleMove = useCallback((clientY) => {
    if (!isDragging) return;
    
    const weight = getWeightFromPosition(clientY);
    if (weight) {
      if (isDragging === 'current') {
        setCurrentWeight(weight);
      } else {
        setTargetWeight(weight); // Только обновляем локальное состояние
      }
    }
}, [isDragging, getWeightFromPosition]);

  const handleStart = (type) => (e) => {
    if (isEditing) return;
    setIsDragging(type);
  };

  const handleEnd = useCallback(() => {
    if (isDragging === 'current') {
      onCurrentWeightChange(currentWeight);
    } else if (isDragging === 'target') {
      onTargetWeightChange(targetWeight); // Добавляем обработку целевого веса
    }
    setIsDragging(null);
}, [isDragging, currentWeight, targetWeight, onCurrentWeightChange, onTargetWeightChange]);

  // Функции для редактирования веса
  const handleEdit = (type, value) => {
    setIsEditing(type);
    setInputValue(value.toString());
  };

  const handleSave = () => {
    const weight = parseFloat(inputValue);
    if (!isNaN(weight) && weight > 0) {
      if (isEditing === 'current' || isEditing === 'current-card') {
        setCurrentWeight(weight);
        onCurrentWeightChange(weight);
      } else if (isEditing === 'target' || isEditing === 'target-card') {
        setTargetWeight(weight);
        onTargetWeightChange(weight);
      }
      setIsEditing(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(null);
    }
  };

  const getWeightZoneColor = (weight) => {
    if (typeof weight !== 'number') return 'gray';
    if (weight < recommendedRange.min) return 'red';
    if (weight > recommendedRange.max) return 'orange'; // Изменили с 'red' на 'orange' для избыточного веса
    return 'green';
  };

  React.useEffect(() => {
    if (!isDragging) return;
  
    // Определяем обработчики внутри useEffect
    const handleMouseMove = (e) => {
      e.preventDefault();
      handleMove(e.clientY);
    };
  
    const handleTouchMove = (e) => {
      e.preventDefault(); // Добавляем это
      const touch = e.touches[0];
      handleMove(touch.clientY);
    };
  
    const touchOptions = { passive: false };    const mouseOptions = { passive: false };
  
    document.body.style.overflow = 'hidden';
    
    // Добавляем обработчики с соответствующими опциями
    window.addEventListener('mousemove', handleMouseMove, mouseOptions);
    window.addEventListener('mouseup', handleEnd, mouseOptions);
    window.addEventListener('touchmove', handleTouchMove, touchOptions);
    window.addEventListener('touchend', handleEnd, touchOptions);
  
    return () => {
      document.body.style.overflow = '';
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, handleMove, handleEnd]);

  React.useEffect(() => {
    setCurrentWeight(initialCurrentWeight);
  }, [initialCurrentWeight]);

  React.useEffect(() => {
    setTargetWeight(initialTargetWeight);
  }, [initialTargetWeight]);

  return (
    <div className="p-6 bg-white rounded-xl shadow-lg select-none">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Левая часть с основной шкалой */}
        <div className="relative w-24 h-96 weight-scale mx-auto">
          {/* Градиентная шкала */}
          <div className="absolute inset-y-0 left-1/2 w-16 -translate-x-1/2 rounded-full overflow-hidden">
            <div className="relative h-full w-full bg-red-100">
              {/* Зона рекомендуемого веса */}
              <div 
                className="absolute w-full bg-green-100 border-y border-green-200"
                style={{
                  top: getPositionY(recommendedRange.max),
                  height: `${((recommendedRange.max - recommendedRange.min) / 120) * 100}%`
                }}
              />
              {/* Зона избыточного веса */}
              <div 
                className="absolute w-full bg-orange-100 border-b border-orange-200"
                style={{
                  top: '0',
                  height: getPositionY(recommendedRange.max)
                }}
              />
            </div>
          </div>

          {/* Деления шкалы */}
          <div className="absolute inset-y-0 right-0 flex flex-col justify-between py-2">
            {[150, 120, 90, 60, 30].map((weight) => (
              <div key={weight} className="flex items-center">
                <div className="w-2 h-px bg-gray-300 mr-1" />
                <span className="text-xs text-gray-500">{weight}</span>
              </div>
            ))}
          </div>

  {/* Маркер текущего веса */}
  <div 
    className={`absolute left-0 w-full flex items-center touch-none ${isDragging === 'current' ? 'cursor-grabbing' : 'cursor-grab'}`}
    style={{ 
      top: getPositionY(currentWeight),
    }}
    onMouseDown={handleStart('current')}
    onTouchStart={handleStart('current')}
  >
            <div className="relative w-full">
              <div className="absolute right-full mr-3 whitespace-nowrap">
                {isEditing === 'current' ? (
                  <div className="bg-white shadow rounded-lg p-1">
                    <input
                      type="number"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onBlur={handleSave}
                      onKeyDown={handleKeyDown}
                      className="w-20 px-2 py-1 text-xs border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                      min="30"
                      max="250"
                      autoFocus
                    />
                  </div>
                ) : (
<div className={`bg-${getWeightZoneColor(currentWeight)}-500 text-white text-xs px-3 py-1 rounded-full`}>
  {formatWeight(currentWeight)} {getTranslation('units.kg', language)}
</div>
                )}
              </div>
              <div className="absolute left-1/2 -translate-x-1/2 flex items-center w-20">
                <div className={`w-full h-0.5 bg-${getWeightZoneColor(currentWeight)}-500 transition-colors`} />
                <div className={`absolute left-1/2 -translate-x-1/2 w-4 h-4 rounded-full bg-${getWeightZoneColor(currentWeight)}-500 border-2 border-white transition-colors`} />
              </div>
            </div>
          </div>

  {/* Маркер целевого веса */}
  <div 
    className={`absolute left-0 w-full flex items-center touch-none ${isDragging === 'target' ? 'cursor-grabbing' : 'cursor-grab'}`}
    style={{ 
      top: getPositionY(targetWeight),
    }}
    onMouseDown={handleStart('target')}
    onTouchStart={handleStart('target')}
  >

            <div className="relative w-full">
              <div className="absolute left-full ml-3 whitespace-nowrap">
                {isEditing === 'target' ? (
                  <div className="bg-white shadow rounded-lg p-1">
                    <input
                      type="number"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onBlur={handleSave}
                      onKeyDown={handleKeyDown}
                      className="w-20 px-2 py-1 text-xs border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      step="0.1"
                      min="30"
                      max="250"
                      autoFocus
                    />
                  </div>
                ) : (
<div className={`bg-${getWeightZoneColor(targetWeight)}-500 text-white text-xs px-3 py-1 rounded-full`}>
  {formatWeight(targetWeight)} {getTranslation('units.kg', language)}
</div>
                )}
              </div>
              <div className="absolute left-1/2 -translate-x-1/2 flex items-center w-20">
                <div className={`w-full h-0.5 bg-${getWeightZoneColor(targetWeight)}-500 transition-colors`} />
                <div className={`absolute left-1/2 -translate-x-1/2 w-4 h-4 rounded-full bg-${getWeightZoneColor(targetWeight)}-500 border-2 border-white transition-colors shadow-sm`} />
              </div>
            </div>
          </div>
        </div>

        {/* Правая часть с информацией */}
        <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">
  {getTranslation('weightProgress.weightRange.title', language)}
</h3>          
          {/* Информационные карточки */}
          <div className="space-y-4">
  <div className={`p-4 bg-${getWeightZoneColor(currentWeight)}-50 rounded-lg transition-colors`}>
  <div className={`text-sm text-${getWeightZoneColor(currentWeight)}-600 font-medium transition-colors`}>
  {getTranslation('weightProgress.weightRange.currentWeight', language)}
</div>    {isEditing === 'current-card' ? (
      <div className="flex items-center gap-2">
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          className="w-24 px-2 py-1 text-2xl font-bold border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          step="0.1"
          min="30"
          max="250"
          autoFocus
        />
<span className="text-2xl font-bold text-gray-600">
  {getTranslation('units.kg', language)}
</span>      </div>
    ) : (
      <div 
        className="group flex items-center gap-2 cursor-pointer"
        onClick={() => handleEdit('current-card', currentWeight)}
      >
        <span className={`text-2xl font-bold text-${getWeightZoneColor(currentWeight)}-700 transition-colors`}>
        {formatWeight(currentWeight)} {getTranslation('units.kg', language)}

</span>
        <FiEdit3 className="w-4 h-4 opacity-0 group-hover:opacity-100 text-gray-400" />
      </div>
    )}
  </div>
  
  <div className={`p-4 bg-${getWeightZoneColor(targetWeight)}-50 rounded-lg transition-colors`}>
  <div className={`text-sm text-${getWeightZoneColor(targetWeight)}-600 font-medium transition-colors`}>
  {getTranslation('weightProgress.weightRange.targetWeight', language)}
</div>    {isEditing === 'target-card' ? (
      <div className="flex items-center gap-2">
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          className="w-24 px-2 py-1 text-2xl font-bold border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          step="0.1"
          min="30"
          max="250"
          autoFocus
        />
<span className="text-2xl font-bold text-gray-600">
  {getTranslation('units.kg', language)}
</span>      </div>
    ) : (
      <div 
        className="group flex items-center gap-2 cursor-pointer"
        onClick={() => handleEdit('target-card', targetWeight)}
      >
<span className={`text-2xl font-bold text-${getWeightZoneColor(targetWeight)}-700 transition-colors`}>
  {formatWeight(targetWeight)} {getTranslation('units.kg', language)}
</span>
        <FiEdit3 className="w-4 h-4 opacity-0 group-hover:opacity-100 text-gray-400" />
      </div>
    )}
            </div>
          </div>

          {/* Пояснения к зонам */}
          <div className="mt-6 space-y-2">
  <p className="text-sm text-gray-600">
    <span className="font-medium text-red-500">
      {getTranslation('weightProgress.weightRange.zones.underweight.title', language)}:
    </span>{' '}
    {getTranslation('weightProgress.weightRange.zones.underweight.description', language)}{' '}
    {recommendedRange.min.toFixed(1)} {getTranslation('units.kg', language)}
  </p>
  <p className="text-sm text-gray-600">
    <span className="font-medium text-green-500">
      {getTranslation('weightProgress.weightRange.zones.normal.title', language)}:
    </span>{' '}
    {getTranslation('weightProgress.weightRange.zones.normal.description', language)}{' '}
    {recommendedRange.min.toFixed(1)} {getTranslation('weightProgress.weightRange.zones.normal.to', language)}{' '}
    {recommendedRange.max.toFixed(1)} {getTranslation('units.kg', language)}
  </p>
  <p className="text-sm text-gray-600">
    <span className="font-medium text-orange-500">
      {getTranslation('weightProgress.weightRange.zones.overweight.title', language)}:
    </span>{' '}
    {getTranslation('weightProgress.weightRange.zones.overweight.description', language)}{' '}
    {recommendedRange.max.toFixed(1)} {getTranslation('units.kg', language)}
  </p>
</div>
        </div>
      </div>
    </div>
  );
};

// Форматирование даты
const formatDate = (dateString, language) => {
  const options = getTranslation('weightProgress.history.date.options', language);
  return new Date(dateString).toLocaleString(language, options);
};

// Основной компонент
const WeightProgressCard = ({ profile, handleWeightChange, language, handleGoalTypeChange, t }) => {
  const [showRecommendations, setShowRecommendations] = useState(true);

  // Получаем все метрики
  const metrics = calculateHealthMetrics(profile);
  
  // Следим за изменениями идеального веса
  React.useEffect(() => {
    const idealWeight = metrics.healthyWeightRange.ideal;
    handleWeightChange('customTargetWeight', idealWeight);
  }, [metrics.healthyWeightRange.ideal, handleWeightChange]);
  
  const targetWeight = profile.customTargetWeight || metrics.healthyWeightRange.ideal;
  const weightDiff = Math.abs(parseFloat(profile.weight) - parseFloat(targetWeight));
  const weeklyRate = metrics.weightChangeRate;
  const timeToGoal = Math.ceil(weightDiff / weeklyRate);

  const handleCurrentWeightSave = (weight) => {
    handleWeightChange('weight', weight);
    
    // Определяем тип цели на основе сравнения с целевым весом
    const currentWeight = parseFloat(weight);
    const targetWeight = parseFloat(profile.customTargetWeight);
    
    let newGoalType;
    if (Math.abs(currentWeight - targetWeight) < 0.5) {
      newGoalType = 'maintain';
    } else {
      newGoalType = currentWeight > targetWeight ? 'loss' : 'gain';
    }
    
    handleWeightChange('goalType', newGoalType);
  };
  
  const handleTargetWeightSave = (weight) => {
    handleWeightChange('customTargetWeight', weight);
    
    // Определяем тип цели на основе разницы весов
    const currentWeight = parseFloat(profile.weight);
    const targetWeight = parseFloat(weight);
    
    let newGoalType;
    if (Math.abs(targetWeight - currentWeight) < 0.5) {
      newGoalType = 'maintain';
    } else {
      newGoalType = targetWeight > currentWeight ? 'gain' : 'loss';
    }
    
    handleWeightChange('goalType', newGoalType);
    setShowRecommendations(true);
  };

  const chartData = [...(profile.weightHistory || [])]
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(record => ({
      date: formatDate(record.date, language),
      weight: parseFloat(record.weight)
    }));

    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 space-y-2 sm:space-y-0">
          <div>
          <h3 className="text-xl font-semibold text-gray-800">
  {getTranslation('weightProgress.title', language)}
</h3>
          </div>
          <div className="flex items-center gap-2">
          <span className="inline-flex px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
  {getTranslation(`weightProgress.goalTypes.${profile.goalType}`, language)}
</span>
<span className="inline-flex px-3 py-1 bg-green-50 text-green-700 rounded-full text-sm">
  {timeToGoal} {getTranslation('weightProgress.weeks', language)}
</span>
          </div>
        </div>
      {showRecommendations && (
        <>
<WeightRangeVisualizer
  initialCurrentWeight={parseFloat(profile.weight)}
  initialTargetWeight={targetWeight}
  recommendedRange={metrics.healthyWeightRange}
  onCurrentWeightChange={handleCurrentWeightSave}
  onTargetWeightChange={handleTargetWeightSave}
  language={language}
/>
        </>
      )}

      <div className="h-48 mt-6">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <XAxis dataKey="date" stroke="#9CA3AF" fontSize={12} />
            <YAxis stroke="#9CA3AF" fontSize={12} domain={['dataMin - 1', 'dataMax + 1']} />
            <Tooltip />
            <ReferenceArea
              y1={metrics.healthyWeightRange.min}
              y2={metrics.healthyWeightRange.max}
              fill="#10B981"
              fillOpacity={0.1}
            />
            <ReferenceLine
              y={metrics.healthyWeightRange.ideal}
              stroke="#10B981"
              strokeDasharray="3 3"
            />
            <Line 
              type="monotone" 
              dataKey="weight" 
              stroke="#3B82F6" 
              strokeWidth={2}
              dot={{ fill: '#3B82F6', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6">
      <h4 className="font-medium mb-4">
  {getTranslation('weightProgress.history.title', language)}
</h4>        <div className="space-y-3">
          {[...(profile.weightHistory || [])]
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((record, index, array) => {
              // Вычисляем разницу с предыдущим весом
              const prevWeight = array[index + 1]?.weight;
              const weightDiff = prevWeight ? (record.weight - prevWeight) : 0;
              const showDiff = index < array.length - 1;

              return (
                <div 
                  key={record.date} 
                  className="group flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col">
                    <p className="font-medium text-lg">
  {record.weight} {getTranslation('units.kg', language)}
</p>                      <p className="text-sm text-gray-600">{formatDate(record.date)}</p>
                    </div>
                    {showDiff && (
                      <div className={`flex items-center ${weightDiff > 0 ? 'text-red-500' : 'text-green-500'}`}>
<span className="text-sm font-medium">
  {weightDiff > 0 ? '+' : ''}{weightDiff.toFixed(1)} {getTranslation('units.kg', language)}
</span>
                      </div>
                    )}
                  </div>
                  <button
  onClick={() => handleWeightChange('weightHistory', 'delete', index)}
  className="md:opacity-0 md:group-hover:opacity-100 transition-opacity p-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-full"
>
  <svg 
    className="w-5 h-5" 
    fill="none" 
    stroke="currentColor" 
    viewBox="0 0 24 24"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
    />
  </svg>
</button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WeightProgressCard;