import React, { useState } from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import emailjs from '@emailjs/browser';

const FeedbackCard = ({ t }) => {

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const templateParams = {
        from_name: email,
        to_name: "121",
        from_email: email,
        message: message,
        reply_to: email,
      };

      await emailjs.send(
        'service_7972uq4',
        'template_i2opbrn',
        templateParams,
        'y5wFA4TTdLiJV7Q1E'
      );

      setStatus({
        type: 'success',
        message: t('feedback.successMessage')
      });

      setEmail('');
      setMessage('');
      
      setTimeout(() => {
        setIsFormOpen(false);
        setStatus({ type: '', message: '' });
      }, 3000);

    } catch (error) {
      console.error('EmailJS Error:', error);
      setStatus({
        type: 'error',
        message: `${t('feedback.errorMessage')} (${error.message})`
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full bg-white rounded-xl shadow-md p-6">
      <div className="mb-4">
        <div 
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setIsFormOpen(!isFormOpen)}
        >
          <div className="flex items-center gap-2">
            <FiMessageSquare className="text-blue-600" />
            <h3 className="text-xl font-semibold">{t('feedback.title')}</h3>
          </div>
          <button
            className={`p-2 rounded-lg border transition-all ${
              isFormOpen ? 'bg-blue-50 border-blue-500 text-blue-700' : 'border-gray-200'
            }`}
          >
            {isFormOpen ? t('feedback.cancel') : t('feedback.write')}
          </button>
        </div>
      </div>
      
      {isFormOpen && (
        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              required
              placeholder="example@mail.com"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('feedback.message')}
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none min-h-[120px]"
              required
              placeholder={t('feedback.messagePlaceholder')}
            />
          </div>

          {status.message && (
            <div className={`p-4 rounded-lg ${
              status.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
            }`}>
              {status.message}
            </div>
          )}

          <div className="flex gap-2 justify-end">
            <button
              type="button"
              onClick={() => {
                setIsFormOpen(false);
                setStatus({ type: '', message: '' });
              }}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50 transition-colors"
            >
              {t('feedback.cancel')}
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? t('feedback.sending') : t('feedback.send')}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};


export default FeedbackCard;