import React, { useState } from "react";
import { FaHome,FaUtensils, FaChartBar, FaUser } from "react-icons/fa";
import { MdAddAPhoto } from "react-icons/md";
import { FiAward, FiTrendingUp, FiTarget} from 'react-icons/fi';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useMealManager, MealModal, MealList } from './components/MealManager';
import { ProfileTab } from './components/ProfileTab';

const App = () => {
  const [activeTab, setActiveTab] = useState("home");
  const mealManager = useMealManager();

  // ================ RENDER METHODS ================
  const renderHome = () => {
    const totalCalories = mealManager.meals.reduce((sum, meal) => sum + meal.calories, 0);
    const calorieGoal = 2000;
    const caloriePercentage = Math.round((totalCalories / calorieGoal) * 100);

    return (
      <div className="p-4 md:p-6 space-y-6">
        {/* Карточка прогресса */}
        <div className="bg-white rounded-xl shadow-md p-6 transform hover:scale-[1.02] transition-all">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6">
            <div className="space-y-4 flex-1">
              <h2 className="text-2xl font-bold text-gray-800">Сегодняшний прогресс</h2>
              <div className="space-y-2">
                <p className="text-lg flex justify-between">
                  Потреблено
                  <span className="font-semibold text-green-600">{totalCalories} кал</span>
                </p>
                <p className="text-lg flex justify-between">
                  Осталось
                  <span className="font-semibold text-blue-600">{calorieGoal - totalCalories} кал</span>
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="w-32 h-32 rounded-full flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-500">
                <div className="w-28 h-28 rounded-full bg-white flex items-center justify-center">
                  <span className="text-3xl font-bold bg-gradient-to-br from-blue-500 to-purple-500 bg-clip-text text-transparent">
                    {caloriePercentage}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Кнопка добавления */}
        <button 
          onClick={() => mealManager.setIsModalOpen(true)}
          className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-4 px-6 rounded-xl flex items-center justify-center gap-3 hover:from-blue-600 hover:to-purple-600 transition-all shadow-lg hover:shadow-xl"
        >
          <MdAddAPhoto className="text-2xl" />
          <span className="font-semibold">Добавить прием пищи</span>
        </button>

        {/* Рекомендации */}
        <div className="bg-white rounded-xl shadow-md p-6">
          <h3 className="text-xl font-bold text-gray-800 mb-4 flex items-center gap-2">
            <FiTarget className="text-blue-500" />
            Рекомендации
          </h3>
          <div className="space-y-4">
            {[
              "Увеличить потребление белка",
              "Добавить больше овощей",
              "Уменьшить потребление сахара"
            ].map((tip, index) => (
              <div key={index} className="flex items-center gap-3 p-3 bg-blue-50 rounded-xl">
                <FiAward className="text-blue-500 text-xl flex-shrink-0" />
                <p className="text-gray-700">{tip}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Мотивация */}
        <div className="bg-gradient-to-r from-purple-500 to-pink-500 rounded-xl p-6 text-white shadow-lg">
          <div className="flex items-start gap-4">
            <FiTrendingUp className="text-2xl flex-shrink-0" />
            <div>
              <h4 className="font-semibold mb-2">Мотивация дня</h4>
              <p className="opacity-90">Каждый здоровый выбор - это шаг к вашей цели. Продолжайте!</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFood = () => {
    const totalCalories = mealManager.meals.reduce((sum, meal) => sum + Number(meal.calories), 0);
    const totalProteins = mealManager.meals.reduce((sum, meal) => sum + Number(meal.proteins), 0);
    const totalCarbs = mealManager.meals.reduce((sum, meal) => sum + Number(meal.carbs), 0);
    const totalFats = mealManager.meals.reduce((sum, meal) => sum + Number(meal.fats), 0);
  
    return (
      <div className="p-6 bg-gradient-to-b from-green-50 to-white">
        <h2 className="text-3xl font-bold mb-6 text-green-800">Дневник питания</h2>
        <div className="bg-white rounded-xl shadow-md p-6 mb-6">
          <h3 className="text-2xl font-semibold mb-4 text-green-700">Приемы пищи сегодня</h3>
          <MealList 
            meals={mealManager.meals} 
            onEdit={mealManager.handleEditMeal} 
            onDelete={mealManager.handleDeleteMeal}
          />
        </div>
        
        <button 
          onClick={() => mealManager.setIsModalOpen(true)}
          className="bg-green-600 text-white px-6 py-3 rounded-full flex items-center mb-6 hover:bg-green-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <MdAddAPhoto className="mr-2 text-xl" /> Добавить прием пищи
        </button>
        
        <div className="bg-white rounded-xl shadow-md p-6">
          <h3 className="text-2xl font-semibold mb-4 text-green-700">Итоги дня</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <p className="text-lg flex justify-between items-center">
                <span>Всего калорий:</span>
                <span className="font-bold text-green-600">{totalCalories.toFixed(1)} кал</span>
              </p>
              <p className="text-lg flex justify-between items-center">
                <span>Всего белков:</span>
                <span className="font-bold text-blue-600">{totalProteins.toFixed(1)} г</span>
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-lg flex justify-between items-center">
                <span>Всего углеводов:</span>
                <span className="font-bold text-yellow-600">{totalCarbs.toFixed(1)} г</span>
              </p>
              <p className="text-lg flex justify-between items-center">
                <span>Всего жиров:</span>
                <span className="font-bold text-red-600">{totalFats.toFixed(1)} г</span>
              </p>
            </div>
          </div>
          <p className="text-lg mt-4 pt-4 border-t">
            Приемов пищи: <span className="font-bold text-green-600">{mealManager.meals.length}</span>
          </p>
        </div>
      </div>
    );
  };

  const renderAnalysis = () => {
    const data = [
      { name: "Пн", calories: 2000 },
      { name: "Вт", calories: 2200 },
      { name: "Ср", calories: 1800 },
      { name: "Чт", calories: 2100 },
      { name: "Пт", calories: 2300 },
      { name: "Сб", calories: 1900 },
      { name: "Вс", calories: 2000 },
    ];

    return (
      <div className="p-6 bg-gradient-to-b from-purple-50 to-white">
        <h2 className="text-3xl font-bold mb-6 text-purple-800">Анализ</h2>
        <div className="bg-white rounded-xl shadow-md p-6 mb-6">
          <h3 className="text-2xl font-semibold mb-4 text-purple-700">Потребление калорий</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="calories" stroke="#8884d8" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Дополнительная аналитика */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-xl shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Распределение нутриентов</h3>
            <div className="space-y-4">
              {[
                { label: "Белки", value: 25, color: "bg-blue-500" },
                { label: "Жиры", value: 30, color: "bg-yellow-500" },
                { label: "Углеводы", value: 45, color: "bg-green-500" }
              ].map((nutrient) => (
                <div key={nutrient.label} className="space-y-2">
                  <div className="flex justify-between">
                    <span>{nutrient.label}</span>
                    <span>{nutrient.value}%</span>
                  </div>
                  <div className="w-full h-2 bg-gray-200 rounded-full">
                    <div 
                      className={`h-full rounded-full ${nutrient.color}`}
                      style={{ width: `${nutrient.value}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-md p-6">
            <h3 className="text-xl font-semibold mb-4">Статистика недели</h3>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span>Среднее потребление</span>
                <span className="font-bold">2043 кал</span>
              </div>
              <div className="flex justify-between items-center">
                <span>Лучший день</span>
                <span className="font-bold text-green-600">Вторник</span>
              </div>
              <div className="flex justify-between items-center">
                <span>Дней в норме</span>
                <span className="font-bold text-blue-600">5/7</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // ================ MAIN RENDER ================
  const getContent = () => {
    switch (activeTab) {
      case "home":
        return renderHome();
      case "food":
        return renderFood();
      case "analysis":
        return renderAnalysis();
      case "profile":
        return <ProfileTab />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <main className="flex-grow overflow-y-auto pb-16">
        {getContent()}
        <MealModal mealManager={mealManager} />
      </main>
      
      {/* Навигация */}
      <nav className="fixed bottom-0 w-full bg-white border-t border-gray-200 shadow-lg backdrop-blur-lg bg-opacity-90">
        <ul className="flex justify-around max-w-md mx-auto">
          {[
            { id: "home", icon: FaHome, label: "Главная" },
            { id: "food", icon: FaUtensils, label: "Дневник" },
            { id: "analysis", icon: FaChartBar, label: "Анализ" },
            { id: "profile", icon: FaUser, label: "Профиль" }
          ].map((tab) => (
            <li key={tab.id} className="flex-1">
              <button
                onClick={() => setActiveTab(tab.id)}
                className={`flex flex-col items-center justify-center w-full py-3 px-2 ${
                  activeTab === tab.id 
                    ? "text-blue-600" 
                    : "text-gray-600 hover:text-gray-900"
                } transition-all`}
              >
                <tab.icon className={`text-xl ${activeTab === tab.id ? "scale-110" : ""}`} />
                <span className="text-xs mt-1 font-medium">{tab.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default App;