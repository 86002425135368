import React, { useState, useEffect } from 'react';
import { getTranslation } from './translations'; // Убедитесь, что путь к файлу правильный

export const calculateHealthMetrics = (profile) => {
  const height = parseFloat(profile.height) / 100;
  const weight = parseFloat(profile.weight);
  const age = parseFloat(profile.age);
  const isMale = profile.gender === "Мужской";

  // Расчет BMI
  const bmi = weight / (height * height);

  // Идеальный вес по формуле Devine
  const idealWeight = Number((isMale
    ? 50 + 2.3 * ((height * 100 - 152.4) / 2.54)
    : 45.5 + 2.3 * ((height * 100 - 152.4) / 2.54)).toFixed(1));
  
  const healthyWeightRange = {
    min: Number((18.5 * height * height).toFixed(1)),
    max: Number((24.9 * height * height).toFixed(1)),
    ideal: idealWeight
  };

  // Расчет рекомендуемой скорости изменения веса
  const calculateWeightChangeRate = () => {
    const bmiDiff = bmi - 22;
    if (Math.abs(bmiDiff) <= 2) return 0.25;
    if (Math.abs(bmiDiff) <= 5) return 0.5;
    return 1;
  };

  // Расчет калорий
  const calculateCalories = () => {
    let bmr;
    if (isMale) {
      bmr = 10 * weight + 6.25 * (height * 100) - 5 * age + 5;
    } else {
      bmr = 10 * weight + 6.25 * (height * 100) - 5 * age - 161;
    }

    const activityLevels = {
      inactive: 1.2,
      light: 1.375,
      moderate: 1.55,
      active: 1.725,
      very_active: 1.9
    };

    const tdee = bmr * (activityLevels[profile.lifestyle] || 1.2);
    const maintainCalories = Math.round(tdee);

    return {
      bmr: Math.round(bmr),
      tdee: maintainCalories,
      forLoss: Math.round(tdee * 0.85),
      forGain: Math.round(tdee * 1.15)
    };
  };

  const weightChangeRate = calculateWeightChangeRate();
  const calories = calculateCalories();

  // Расчет времени до цели
  const timeToGoal = () => {
    if (!profile.customTargetWeight) return 0;
    const weightDiff = Math.abs(weight - profile.customTargetWeight);
    return Math.ceil(weightDiff / weightChangeRate);
  };

  return {
    bmi,
    healthyWeightRange,
    idealWeight,
    weightChangeRate,
    calories,
    timeToGoal: timeToGoal()
  };
};

const determineRecommendedCaloriesType = (profile) => {
  if (!profile.customTargetWeight) {
    return 'maintain';
  }

  const weightDiff = profile.weight - profile.customTargetWeight;
  
  if (Math.abs(weightDiff) < 0.5) {
    return 'maintain';
  }
  
  if (weightDiff > 0) {
    return 'loss';
  }
  
  return 'gain';
};

export const Analytics = ({ profile, onCaloriesChange, language = 'ru' }) => {  const metrics = calculateHealthMetrics(profile);
  const [isEditingCalories, setIsEditingCalories] = useState(false);
  const [customCalories, setCustomCalories] = useState("");
  const [selectedCaloriesType, setSelectedCaloriesType] = useState(profile.caloriesType || 'maintain');
  const [savedCustomCalories, setSavedCustomCalories] = useState(null);

  useEffect(() => {
    if (profile && metrics) {
      const recommendedType = determineRecommendedCaloriesType(profile);
      if (!savedCustomCalories) { // Добавлено условие
        setSelectedCaloriesType(recommendedType);
        const recommendedCalories = {
          maintain: metrics.calories.tdee,
          loss: metrics.calories.forLoss,
          gain: metrics.calories.forGain
        }[recommendedType];
        
        onCaloriesChange?.({ type: recommendedType, calories: recommendedCalories });
      }
    }
  }, [metrics, onCaloriesChange, profile, savedCustomCalories]); // Добавлен savedCustomCalories в зависимости

  const handleCustomCaloriesSave = () => {
    const calories = parseInt(customCalories);
    if (!isNaN(calories) && calories > 0) {
      setSavedCustomCalories(calories);
      setSelectedCaloriesType('custom');
      onCaloriesChange?.({
        type: 'custom',
        calories: calories
      });
      setIsEditingCalories(false);
      setCustomCalories("");
    }
  };

  const handleCustomCaloriesRemove = () => {
    setSavedCustomCalories(null);
    const recommendedType = determineRecommendedCaloriesType(profile);
    setSelectedCaloriesType(recommendedType);
    const recommendedCalories = {
      maintain: metrics.calories.tdee,
      loss: metrics.calories.forLoss,
      gain: metrics.calories.forGain
    }[recommendedType];
    
    onCaloriesChange?.({
      type: recommendedType,
      calories: recommendedCalories
    });
  };

  if (!metrics) return null;

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6">
        <h3 className="text-xl font-semibold">{getTranslation('analytics.title', language)}</h3>
      </div>
      
      <div className="p-6 pt-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-sm text-gray-600">{getTranslation('analytics.basalMetabolism.title', language)}</p>
            <p className="text-2xl font-bold text-blue-700">
              {metrics.calories.bmr}
              <span className="text-base font-normal text-blue-600 ml-1">
                {getTranslation('units.kcal', language)}
              </span>
            </p>
            <p className="text-xs text-gray-500 mt-1">{getTranslation('analytics.basalMetabolism.description', language)}</p>
          </div>

          <div className="bg-green-50 p-4 rounded-lg">
            <p className="text-sm text-gray-600">{getTranslation('analytics.dailyRate.title', language)}</p>
            <p className="text-2xl font-bold text-green-700">
              {metrics.calories.tdee}
              <span className="text-base font-normal text-green-600 ml-1">
                {getTranslation('units.kcal', language)}
              </span>
            </p>
            <p className="text-xs text-gray-500 mt-1">{getTranslation('analytics.dailyRate.description', language)}</p>
          </div>

          <div className="md:col-span-2 bg-blue-50 p-4 rounded-lg">
            <h5 className="font-medium text-blue-900 mb-2">{getTranslation('analytics.weightRecommendations.title', language)}</h5>
            <div className="space-y-2 text-sm text-blue-800">
              <p>• {getTranslation('analytics.weightRecommendations.bmi', language)}: {metrics.bmi.toFixed(1)}</p>
              <p>• {getTranslation('analytics.weightRecommendations.recommendedRange', language)}: {metrics.healthyWeightRange.min} - {metrics.healthyWeightRange.max} кг</p>
              <p>• {getTranslation('analytics.weightRecommendations.idealWeight', language)}: {metrics.idealWeight} кг</p>
              <p>• {getTranslation('analytics.weightRecommendations.changeRate', language)}: {metrics.weightChangeRate} кг/неделю</p>
              {metrics.timeToGoal > 0 && (
                <p>• {getTranslation('analytics.weightRecommendations.timeToGoal', language)}: {metrics.timeToGoal} недель</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6">
          <p className="text-sm font-medium text-gray-600 mb-3">{getTranslation('analytics.targetCalories.title', language)}:</p>
          <div className="space-y-2.5">
            <div className={`p-4 rounded-lg transition-all duration-200 ${
              selectedCaloriesType === 'maintain' 
                ? 'bg-green-50 ring-1 ring-green-100' 
                : 'bg-gray-50'
            }`}>
              <div className="flex justify-between items-center">
                <div>
                  <p className={`text-sm font-medium ${
                    selectedCaloriesType === 'maintain' ? 'text-green-700' : 'text-gray-700'
                  }`}>
                    {getTranslation('analytics.targetCalories.maintenance.title', language)}
                  </p>
                  <p className="text-xs text-gray-500 mt-0.5">{getTranslation('analytics.targetCalories.maintenance.description', language)}</p>
                </div>
                <p className={`text-sm font-medium ${
                  selectedCaloriesType === 'maintain' ? 'text-green-600' : 'text-gray-600'
                }`}>
                  {metrics.calories.tdee} {getTranslation('units.kcal', language)}
                </p>
              </div>
            </div>

            <div className={`p-4 rounded-lg transition-all duration-200 ${
              selectedCaloriesType === 'loss' 
                ? 'bg-green-50 ring-1 ring-green-100' 
                : 'bg-gray-50'
            }`}>
              <div className="flex justify-between items-center">
                <div>
                  <p className={`text-sm font-medium ${
                    selectedCaloriesType === 'loss' ? 'text-green-700' : 'text-gray-700'
                  }`}>
                    {getTranslation('analytics.targetCalories.loss.title', language)}
                  </p>
                  <p className="text-xs text-gray-500 mt-0.5">{getTranslation('analytics.targetCalories.loss.description', language)}</p>
                </div>
                <p className={`text-sm font-medium ${
                  selectedCaloriesType === 'loss' ? 'text-green-600' : 'text-gray-600'
                }`}>
                  {metrics.calories.forLoss} {getTranslation('units.kcal', language)}
                </p>
              </div>
            </div>

            <div className={`p-4 rounded-lg transition-all duration-200 ${
              selectedCaloriesType === 'gain' 
                ? 'bg-green-50 ring-1 ring-green-100' 
                : 'bg-gray-50'
            }`}>
              <div className="flex justify-between items-center">
                <div>
                  <p className={`text-sm font-medium ${
                    selectedCaloriesType === 'gain' ? 'text-green-700' : 'text-gray-700'
                  }`}>
                    {getTranslation('analytics.targetCalories.gain.title', language)}
                  </p>
                  <p className="text-xs text-gray-500 mt-0.5">{getTranslation('analytics.targetCalories.gain.description', language)}</p>
                </div>
                <p className={`text-sm font-medium ${
                  selectedCaloriesType === 'gain' ? 'text-green-600' : 'text-gray-600'
                }`}>
                  {metrics.calories.forGain} {getTranslation('units.kcal', language)}
                </p>
              </div>
            </div>

            {selectedCaloriesType === 'custom' && savedCustomCalories && (
              <div className="p-4 rounded-lg bg-green-50 ring-1 ring-green-100 group relative">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm font-medium text-green-700">
                      {getTranslation('analytics.targetCalories.custom.title', language)}
                    </p>
                    <p className="text-xs text-gray-500 mt-0.5">{getTranslation('analytics.targetCalories.custom.description', language)}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-green-600">
                      {savedCustomCalories} {getTranslation('units.kcal', language)}
                    </p>
                    <button
                      onClick={handleCustomCaloriesRemove}
                      className="p-1 rounded-full hover:bg-green-100 text-green-600 hover:text-green-700 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!isEditingCalories && !savedCustomCalories && (
            <div className="mt-3 flex justify-end">
              <button
                onClick={() => setIsEditingCalories(true)}
                className="text-xs text-green-600 hover:text-green-700 font-medium flex items-center gap-1"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                {getTranslation('analytics.targetCalories.custom.addButton', language)}
              </button>
            </div>
          )}

          {isEditingCalories && (
            <div className="mt-3 flex items-center gap-2 justify-end">
              <input
                type="number"
                value={customCalories}
                onChange={(e) => setCustomCalories(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleCustomCaloriesSave()}
                className="w-24 px-2.5 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500/20 focus:border-green-500"
                placeholder={getTranslation('analytics.targetCalories.custom.placeholder', language)}
              />
              <button
                onClick={handleCustomCaloriesSave}
                className="px-2.5 py-1 text-xs font-medium text-white bg-green-500 rounded-md hover:bg-green-600 transition-colors"
              >
                {getTranslation('analytics.targetCalories.custom.add', language)}
              </button>
              <button
                onClick={() => {
                  setIsEditingCalories(false);
                  setCustomCalories("");
                }}
                className="px-2.5 py-1 text-xs text-gray-600 hover:text-gray-700"
              >
                {getTranslation('analytics.targetCalories.custom.cancel', language)}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
  
  export default Analytics;