import React, { useState, useCallback } from 'react';
import { FiAward, FiEdit3 } from 'react-icons/fi';
import WeightProgressCard from './WeightProgressCard';
import Analytics from './Analytics';
import FeedbackCard from './FeedbackCard';
import { getTranslation } from './translations';

// Вспомогательные функции
const getInitials = (name) => 
  name.split(' ').map(part => part[0]).join('');

// Компоненты форм
const FormField = ({ item, editing, handleInputChange, handleWeightChange, value, t }) => {
  const [localValue, setLocalValue] = useState(value);

  React.useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleLocalChange = (e) => {
    setLocalValue(e.target.value);
  };

  const handleBlur = () => {
    if (item.type === 'number') {
      const numValue = parseFloat(localValue);
      if (!isNaN(numValue) && numValue > 0) {
        if (item.name === 'weight') {
          handleWeightChange('weight', localValue);
        } else {
          handleInputChange({
            target: { name: item.name, value: localValue }
          });
        }
      } else {
        setLocalValue(value);
      }
    } else {
      handleInputChange({
        target: { name: item.name, value: localValue }
      });
    }
  };

  if (!editing) {
    return (
      <div className={`bg-gray-50 p-3 rounded-lg ${item.fullWidth ? 'col-span-2' : ''}`}>
        <p className="text-sm text-gray-600">{typeof item.label === 'function' ? item.label(t) : item.label}</p>
        <DisplayValue item={item} value={value} t={t} />
      </div>
    );
  }

  if (item.type === "select") {
    return (
      <div className={`bg-gray-50 p-3 rounded-lg ${item.fullWidth ? 'col-span-2' : ''}`}>
        <p className="text-sm text-gray-600">{typeof item.label === 'function' ? item.label(t) : item.label}</p>
        <SelectField item={item} handleInputChange={handleInputChange} value={value} t={t} />
      </div>
    );
  }

  return (
    <div className={`bg-gray-50 p-3 rounded-lg ${item.fullWidth ? 'col-span-2' : ''}`}>
      <p className="text-sm text-gray-600">{typeof item.label === 'function' ? item.label(t) : item.label}</p>
      <div className="relative">
        <input
          type="text"
          value={localValue}
          onChange={handleLocalChange}
          onBlur={handleBlur}
          className="w-full p-2 pr-12 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
        />
        {item.unit && (
          <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none bg-white px-1">
            {typeof item.unit === 'function' ? item.unit(t) : item.unit}
          </span>
        )}
      </div>
    </div>
  );
};

const DisplayValue = ({ item, value, t }) => (
  <div>
    <p className="text-lg font-semibold text-gray-800">
      {item.name === "lifestyle" 
        ? (typeof item.options.find(opt => opt.value === value)?.label === 'function' 
            ? item.options.find(opt => opt.value === value)?.label(t)
            : item.options.find(opt => opt.value === value)?.label)
        : item.type === "select"
          ? value
          : item.unit
            ? `${value} ${typeof item.unit === 'function' ? item.unit(t) : item.unit}`
            : value
      }
    </p>
    {item.name === "lifestyle" && value && (
      <p className="text-sm text-gray-500 italic mt-1">
        {typeof item.options.find(opt => opt.value === value)?.description === 'function'
          ? item.options.find(opt => opt.value === value)?.description(t)
          : item.options.find(opt => opt.value === value)?.description}
      </p>
    )}
  </div>
);

const SelectField = ({ item, handleInputChange, value, t }) => {
  if (item.name === "lifestyle") {
    return (
      <div className="space-y-2">
        <select
          name={item.name}
          value={value}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none mt-1"
        >
          {item.options.map((option) => (
            <option key={option.value || option} value={option.value || option}>
              {typeof option.label === 'function' ? option.label(t) : option.label || option}
            </option>
          ))}
        </select>
        <p className="text-sm text-gray-500 italic pl-1">
          {typeof item.options.find(opt => opt.value === value)?.description === 'function'
            ? item.options.find(opt => opt.value === value)?.description(t)
            : item.options.find(opt => opt.value === value)?.description}
        </p>
      </div>
    );
  }
  
  return (
    <div 
      onClick={() => handleInputChange({
        target: { 
          name: "gender", 
          value: value === "Мужской" ? "Женский" : "Мужской" 
        }
      })}
      className="text-lg font-semibold cursor-pointer hover:text-blue-600 p-2"
    >
      {value}
    </div>
  );
};

// Карточки информации
const BasicInfoCard = ({ profile, editing, setEditing, handleInputChange, handleWeightChange, fields, t }) => (
  <div className="bg-white rounded-xl shadow-md">
    <div className="p-6 flex items-center justify-between">
      <h3 className="text-xl font-semibold">{t('basicInfo')}</h3>
      <button
        onClick={() => setEditing(!editing)}
        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
      >
        <FiEdit3 className="w-5 h-5 text-blue-600" />
      </button>
    </div>
    <div className="p-6 pt-0">
      <div className="grid grid-cols-2 gap-4">
        {fields.map((item) => (
          <FormField 
            key={typeof item.label === 'function' ? item.label(t) : item.label}
            item={item}
            editing={editing}
            handleInputChange={handleInputChange}
            handleWeightChange={handleWeightChange}
            value={profile[item.name]}
            t={t}
          />
        ))}
      </div>
    </div>
  </div>
);

const AchievementsCard = ({ achievements, t }) => (
  <div className="bg-white rounded-xl shadow-md">
    <div className="p-6">
      <h3 className="text-xl font-semibold flex items-center gap-2">
        <FiAward className="text-yellow-500" />
        {t('achievements')}
      </h3>
    </div>
    <div className="p-6 pt-0">
      <div className="flex flex-wrap gap-2">
        {achievements.map((achievement) => (
          <span 
            key={achievement} 
            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                     bg-yellow-100 text-yellow-800 transition-all hover:bg-yellow-200"
          >
            {achievement}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const NutritionistCard = ({ nutritionist, t }) => (
  <div className="bg-white rounded-xl shadow-md">
    <div className="p-6">
      <h3 className="text-xl font-semibold">{t('myNutritionist')}</h3>
    </div>
    <div className="p-6 pt-0">
      <div className="flex items-start gap-4">
        <div className="w-12 h-12 rounded-full bg-purple-100 flex items-center justify-center 
                      text-purple-600 font-semibold">
          {getInitials(nutritionist.name)}
        </div>
        <div className="flex-1">
          <h3 className="font-semibold">{nutritionist.name}</h3>
          <p className="text-sm text-gray-600">{nutritionist.specialty}</p>
          <div className="flex items-center gap-2 mt-2">
            <span className="text-yellow-500">★</span>
            <span className="font-medium">{nutritionist.rating}</span>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            {t('nextAppointment')}: {new Date(nutritionist.nextAppointment).toLocaleDateString()}
          </p>
        </div>
        <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                       transition-colors">
          {t('book')}
        </button>
      </div>
    </div>
  </div>
);
const LanguageSelector = ({ language, onLanguageChange, t }) => {
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'ru', label: 'Русский', flag: '🇷🇺' },
    { code: 'en', label: 'English', flag: '🇬🇧' },
    { code: 'uk', label: 'Українська', flag: '🇺🇦' }
  ];

  const currentLanguage = languages.find(lang => lang.code === language);

  return (
    <div className="bg-white rounded-xl shadow-md">
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-4">{t('settings.language')}</h3>
        <div className="relative">
          {isOpen && (
            <div className="absolute bottom-full left-0 right-0 mb-2 bg-white rounded-lg shadow-lg overflow-hidden z-50">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => {
                    onLanguageChange(lang.code);
                    setIsOpen(false);
                  }}
                  className={`w-full flex items-center gap-3 p-4 hover:bg-gray-50 transition-colors
                    ${lang.code === language ? 'bg-blue-50 text-blue-600' : ''}
                  `}
                >
                  <span className="text-2xl">{lang.flag}</span>
                  <span className="font-medium">{lang.label}</span>
                </button>
              ))}
            </div>
          )}

          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center gap-3">
              <span className="text-2xl">{currentLanguage.flag}</span>
              <span className="font-medium">{currentLanguage.label}</span>
            </div>
            <svg
              className={`w-5 h-5 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
// Основной компонент
export const ProfileTab = () => {
  const [editing, setEditing] = useState(false);
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || 'ru';
  });
  
  const t = useCallback((key) => getTranslation(key, language), [language]);

  const handleLanguageChange = useCallback((newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  }, []);

  const [profile, setProfile] = useState({
    name: "Александр П.",
    weight: "70",
    height: "175",
    age: "30",
    gender: "Мужской",
    desiredWeight: "65",
    weightLossRate: "0.5",
    initialWeight: "70",
    startDate: new Date().toISOString(),
    weightHistory: [],
    achievements: ["Неделя без сладкого", "10000 шагов", "Дневник 7 дней"],
    goalType: "loss",
    lifestyle: "moderate",
    caloriesType: 'maintain',
    targetCalories: null
  });

  const [selectedDietitian] = useState({
    name: "Др. Надежда Чиконенко",
    specialty: "Спортивный диетолог",
    rating: 4.9,
    nextAppointment: "2024-11-02"
  });
  
  const handleWeightChange = useCallback((field, value, extraData) => {
    setProfile(prev => {
      if (field === 'weightHistory' && value === 'delete') {
        const newHistory = [...prev.weightHistory];
        newHistory.splice(extraData, 1);
        return {
          ...prev,
          weightHistory: newHistory,
          weight: newHistory.length > 0 ? newHistory[0].weight : prev.weight
        };
      }
      
      if (field === 'weight') {
        const newWeight = parseFloat(value);
        const lastRecord = prev.weightHistory[0];
        
        if (!lastRecord || lastRecord.weight !== newWeight) {
          const newRecord = {
            weight: newWeight,
            date: new Date().toISOString()
          };
          
          return {
            ...prev,
            weight: value,
            weightHistory: [newRecord, ...(prev.weightHistory || [])]
          };
        }
      }
      
      return {
        ...prev,
        [field]: value
      };
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setProfile(prev => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleGoalTypeChange = useCallback((type) => {
    setProfile(prev => ({
      ...prev,
      goalType: type
    }));
  }, []);

  return (
    <div className="p-6 space-y-6 bg-gradient-to-b from-blue-50 to-white min-h-screen">

      
      <div className="mt-20 grid gap-6 md:grid-cols-2">
        <BasicInfoCard 
          profile={profile}
          editing={editing}
          setEditing={setEditing}
          handleInputChange={handleInputChange}
          handleWeightChange={handleWeightChange}
          fields={profileFields}
          t={t}
          language={language}
        />
        <Analytics 
          profile={profile}
          t={t} 
          language={language}
        />
        <WeightProgressCard 
          profile={profile}
          handleGoalTypeChange={handleGoalTypeChange}
          handleWeightChange={handleWeightChange}
          t={t}
          language={language}
        />
        <AchievementsCard 
          achievements={profile.achievements}
          t={t}
          language={language}
        />
        <NutritionistCard 
          nutritionist={selectedDietitian}
          t={t}
          language={language}
        />
        <FeedbackCard 
          t={t}
          language={language}
        />
<LanguageSelector
  language={language}
  onLanguageChange={handleLanguageChange}
  t={t}
/>
      </div>
    </div>
  );
};

// Конфигурация полей
const profileFields = [
  { 
    label: t => t('fields.name'),
    name: "name",
    type: "text",
    fullWidth: true
  },
  { 
    label: t => t('fields.weight'),
    name: "weight",
    unit: t => t('units.kg'),
    type: "number",
    min: 30,
    max: 250
  },
  { 
    label: t => t('fields.height'),
    name: "height",
    unit: t => t('units.cm'),
    type: "number",
    min: 100,
    max: 250
  },
  { 
    label: t => t('fields.age'),
    name: "age",
    unit: t => t('units.years'),
    type: "number",
    min: 18,
    max: 100
  },
  { 
    label: t => t('fields.gender'),
    name: "gender",
    type: "select",
    options: [
      { value: "male", label: t => t('gender.male') },
      { value: "female", label: t => t('gender.female') }
    ]
  },
  {
    label: t => t('fields.lifestyle'),
    name: "lifestyle",
    type: "select",
    fullWidth: true,
    options: [
      {
        value: "inactive",
        label: t => t('lifestyleOptions.inactive.label'),
        description: t => t('lifestyleOptions.inactive.description'),
        pal: 1.2
      },
      {
        value: "light",
        label: t => t('lifestyleOptions.light.label'),
        description: t => t('lifestyleOptions.light.description'),
        pal: 1.375
      },
      {
        value: "moderate",
        label: t => t('lifestyleOptions.moderate.label'),
        description: t => t('lifestyleOptions.moderate.description'),
        pal: 1.55
      },
      {
        value: "active",
        label: t => t('lifestyleOptions.active.label'),
        description: t => t('lifestyleOptions.active.description'),
        pal: 1.725
      },
      {
        value: "very_active",
        label: t => t('lifestyleOptions.very_active.label'),
        description: t => t('lifestyleOptions.very_active.description'),
        pal: 1.9
      }
    ]
  }
];

export default ProfileTab;